import React, { useState } from 'react';
import { AuthenticationDetails, CognitoUser, CognitoUserAttribute, CognitoUserPool } from 'amazon-cognito-identity-js';
import { TextField, Button, Paper, Typography, Container, Link } from '@mui/material';
import { styled } from '@mui/system';

  const StyledContainer = styled(Container)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
  }));
  
  const StyledPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  }));
  
  const StyledForm = styled('form')(({ theme }) => ({
    width: '100%',
    marginTop: theme.spacing(1),
  }));
  
  const StyledSubmitButton = styled(Button)(({ theme }) => ({
    marginTop: theme.spacing(2),
  }));

const Login = ({onLogin, onNeedRegister}) => {

  const [username, setUsername] = useState(undefined);
  const [password, setPassword] = useState(undefined);
 
  const handleRegister = () => {
    onNeedRegister();
  };

  const handleLogin = () => {
    const userPool = new CognitoUserPool({
      UserPoolId: 'us-east-2_5bV3wZbbm',
      ClientId: '1p663tk0cf38mde6gtjf4t2ovn',
    });
 
    const userData = {
      Username: username,
      Pool: userPool,
    };

    const authenticationData = {
      Username: username,
      Password: password,
    };

    const authenticationDetails = new AuthenticationDetails(authenticationData);
    const cognitoUser = new CognitoUser(userData);

    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: (session) => {
        console.log('Sesión iniciada con éxito:', session);
        onLogin(cognitoUser, session);
        // Redirecciona al usuario a la página de inicio o al panel de control
      },
      onFailure: (err) => {
        console.error('Error al iniciar sesión:', err);
        // Muestra un mensaje de error al usuario
      },
      newPasswordRequired: (userAttributes, requiredAttributes) => {
        // El usuario debe establecer una nueva contraseña (manejar este caso según tus necesidades)
      },
    });
  };

  return (
    <StyledContainer component="main" maxWidth="xs" >
      <StyledPaper elevation={3}>
        <Typography variant="h5">Iniciar sesión</Typography>
        <StyledForm>
        
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            label="Nombre de usuario"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
           
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            label="Contraseña"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            id="custom-username-field"
            name="custom-username"
            autoComplete='off'
        
          />
          <StyledSubmitButton
            type="button"
            fullWidth
            variant="contained"
            color="primary"
            onClick={handleLogin}
          >
            Iniciar sesión
          </StyledSubmitButton>
          <Link onClick={handleRegister}>Nuevo Usuario?</Link>
        </StyledForm>
      </StyledPaper>
    </StyledContainer>
  );
};

export default Login;
