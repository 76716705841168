import React from 'react';
import PropTypes from 'prop-types'; // Import PropTypes
import { DataGrid, GridRowsProp, GridColDef, GridActionsCell, GridActionsCellItem} from '@mui/x-data-grid';
import { List, ListItemAvatar, Avatar, ListItemText, ListItemButton } from '@mui/material';
import { deepPurple, deepOrange } from '@mui/material/colors';
import Ajustes from 'pages/ajustePages/Ajustes';
import {useNavigation} from 'lib/NavigationContext'
import CreateIcon from '@mui/icons-material/Create';
import EditAjuste from 'pages/ajustePages/EditAjuste';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const AjusteGrid = ({ employee, ajustes, onSelected, onDeleted }) => {
    const { navigateTo } = useNavigation();

    const deleteAction = (id) => {
        if (onDeleted)
            onDeleted(id);
    }
    const selectAction = (id) => {
        if (onSelected)
            onSelected(id);
    }
    const columns = () => {
        const cols = [
            { field: 'Cedula', headerName: 'Cedula', width: 100 },
            {
                field: 'FechaInicio', headerName: 'Inicia', width: 100,
                valueGetter: (params) => {
                    if (params.value) {
                        return new Date(params.value+"T00:00").toLocaleDateString("es-CR");
                    }

                    return "-";
                }
            },
            {
                field: 'FechaFinal', headerName: 'Termina', width: 100,
                valueGetter: (params) => {
                    if (params.value) {
                        return new Date(params.value+"T00:00").toLocaleDateString("es-CR");
                    }

                    return "-";
                },
            },
            {
                field: 'Porcentual', headerName: 'Porcentual', width: 100,
                valueGetter: (params) => {
                    if (params.value != undefined) {
                        return params.value ? "Sí" : "No";
                    }

                    return "No";
                }
            },
            {
                field: 'Cantidad', headerName: 'Cantidad', width: 100,
                valueGetter: (params) => {
                    if (!params.value) {
                        return params.value;
                    }
                    // Convert the decimal value to a percentage
                    return params.value;
                },
            },
            { field: 'Razon', headerName: 'Razon', width: 200 },
            {
                field: 'actions',
                width: 30,
                type: 'actions',
                getActions: ({ id, row }) => {
                  return [
                    <GridActionsCellItem
                      icon={<CreateIcon/>}
                      label="Edit"
                      onClick={() => {
                        navigateTo(<EditAjuste employee={employee} oldAjuste={row} />)
                      }}
                    />,
                  ];
                },
            },
            {
                field: 'action',
                width: 30,
                type: 'actions',
                getActions: ({ id, row }) => {
                  return [
                    <GridActionsCellItem
                      icon={<DeleteIcon/>}
                      label="DELETE"
                      onClick={() => {
                        deleteAction (id)
                      }}
                    />,
                  ];
                },
            },
        ];
        
        return cols;

    }

// Basic validation to check if ajustes is an array
    if (!Array.isArray(ajustes)) {
        return <div>Error: ajustes is not an array</div>;
    }

    return (
        <DataGrid rows={ajustes} columns={columns()}
            onRowSelectionModelChange={selectAction}
            getRowId={(row) => { return row ? row.IDAjuste : 0; }} sx={{ height: 250 }}
            initialState={{
                sorting: {
                    sortModel: [{ field: 'Fecha', sort: 'desc' }],
                },
            }}
        />
    );
};

// PropTypes for type checking
AjusteGrid.propTypes = {
    ajustes: PropTypes.arrayOf(PropTypes.shape({
        IDAjuste: PropTypes.string.isRequired,
        Cedula: PropTypes.string.isRequired,
        FechaFinal: PropTypes.string.isRequired,
        FechaInicio: PropTypes.string.isRequired,
        Cantidad: PropTypes.number.isRequired,
        Razon: PropTypes.string.isRequired,
        Porcentual: PropTypes.bool.isRequired
    })).isRequired,
    onSelected: PropTypes.func.isRequired,
    onDeleted: PropTypes.func.isRequired
};

export default AjusteGrid;
