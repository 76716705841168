import React, { useState, useEffect } from 'react';
import { useApiManager } from 'lib/ApiContext';
import Box from '@mui/material/Box';
import { Stack, Typography, Avatar, Divider, Button } from '@mui/material';
import { deepPurple, grey } from '@mui/material/colors';
import Skeleton from '@mui/material/Skeleton';
import Paper from '@mui/material/Paper';
import ExceptionGrid from 'components/ExceptionGrid';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import NewException from 'pages/exceptionsPages/NewException';
import DeductionGrid from 'components/DeductionGrid';
import NewDeduction from 'pages/deductionPages/NewDeduction';
import NewAjuste from 'pages/ajustePages/NewAjuste';
import AjusteGrid from 'components/AjusteGrid';
import {useNavigation} from '../../lib/NavigationContext'
import PayRollPage from 'pages/Reports/PayRollPage';
import IncapacitieGrid from 'components/IncapacitieGrid';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';
import NewIncapacitie from 'pages/incapcitiesPages/NewIncapacitie';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { CPTextField } from 'components/CPStyles';
import CreateIcon from '@mui/icons-material/Create';
import EditEmployee from './EditEmployee';
import EditException from 'pages/exceptionsPages/EditException';
import EditIncapacitie from 'pages/incapcitiesPages/EditIncapacitie';
import EditDeduction from 'pages/deductionPages/EditDeduction';
import EditAjuste from 'pages/ajustePages/EditAjuste';
import PaymentsGrid from 'components/PaymentsGrid';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';


export const CPTabPanel = (props) => {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  CPTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  

const Employee = ({ cedula }) => {
    const api = useApiManager();
    const [employee, setEmployee] = useState(null);
    const [exceptions, setExceptions] = useState([]);
    const [incapacities, setIncapacities] = useState([]);
    const [deductions, setDeductions] = useState([]);
    const [ajustes, setAjustes] = useState([]);
    const [payments, setPayments] = useState([]);
    const { navigateTo, showAlert } = useNavigation();
    const [selectedTab, setSelectedTab] = React.useState(0);
    const formatter = new Intl.NumberFormat('es-CR', { style: 'currency', currency: 'CRC' });
    const [periodoPago, setPeridoPago] = useState(null);

    useEffect(() => {
        
        const today = new Date();
        const dd = String(today.getDate()).padStart(2, '0');
        const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        const yyyy = today.getFullYear();
        const startDate = yyyy + "-" + mm + '-01';
        const endDate = yyyy + "-" + mm + '-' + dd;
        setPeridoPago({ "fechaInicio": startDate, "fechaFinal": endDate });

        api.GetEmployee(cedula).then(r => setEmployee(r));
        api.GetExceptionsByCedula(cedula).then( r => setExceptions(r));
        api.GetIncapacitiesByCedula(cedula).then( r => setIncapacities(r));
        api.GetDeductionsByCedula(cedula).then( r => setDeductions(r));
        api.GetAjustesByCedula(cedula).then( r => setAjustes(r));
        api.GetEmployeePayments(cedula).then( r => setPayments(r));
    }, [cedula]);

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    const onSelectedException = (id) => {

    }
    const onSelectedIncapacities = (id) => {

    }
    const onSelectedDeduction = (id) => {

    }

    const onSelectedAjuste = (id) => {
        console.log(id);
    }

    

    const onSelectePayment = (id) => {
        console.log(id);
    }
    const onDeletePayment = (id) => {
        showAlert(<Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}><WarningAmberIcon sx={{color: 'red'}}/>"Cuidado!"</Box>, "Esta seguro que quiere borrar este registro de pago? una ves borrado no se puede recuperar y puede que el empleado ya tenga la colilla de pago.", async ()  => 
        { 
            await api.DeletePago(id, employee.Cedula);
            setPayments( await api.GetEmployeePayments(cedula));

        }, true);
    }

    const onAddException = () => {
        navigateTo(<NewException employee={employee} />)
    }
    const onDeleteException = (id) => {
        showAlert(<Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}><WarningAmberIcon sx={{color: 'red'}}/>"Cuidado!"</Box>, "Esta seguro que quiere borrar este excepción? una ves borrado no se puede recuperar.", async ()  => 
        { 
            setExceptions([]);
            await api.DeleteException(id, employee.Cedula)
            setExceptions( await api.GetExceptionsByCedula(employee.Cedula));
        }, true);
    }

    const onAddIncapacities = () => {
        navigateTo(<NewIncapacitie employee={employee} />)
    }

    const onDeleteIncapacitie = (id) => {
        showAlert(<Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}><WarningAmberIcon sx={{color: 'red'}}/>"Cuidado!"</Box>, "Esta seguro que quiere borrar esta incapacidad? una ves borrado no se puede recuperar.", async ()  => 
        { 
            setIncapacities([]);
            await api.DeleteIncapacitie(id, employee.Cedula);
            setIncapacities(await api.GetIncapacitiesByCedula(employee.Cedula));
        }, true);
    }

    
    const onAddDeduction = () => {
        navigateTo(<NewDeduction employee={employee} />)
    }
    const onDeleteDeduction = (id) => {
        showAlert(<Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}><WarningAmberIcon sx={{color: 'red'}}/>"Cuidado!"</Box>, "Esta seguro que quiere borrar esta deducción? una ves borrado no se puede recuperar.", async ()  => 
        { 
            setDeductions([]);
            await api.DeleteDeduction(id, employee.Cedula) 
            setDeductions(await api.GetDeductionsByCedula(cedula));
        }, true);
    }


    const onAddAjuste = () => {
        navigateTo(<NewAjuste employee={employee} />)
    }
    const onDeleteAjuste = (id)  => {
        //alert("delete ajuste");
        showAlert(<Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}><WarningAmberIcon sx={{color: 'red'}}/>"Cuidado!"</Box>, "Esta seguro que quiere borrar este ajuste? una ves borrado no se puede recuperar.", async ()  => 
        { 
            setAjustes([]); 
            await api.DeleteAjuste(id, employee.Cedula);
            setAjustes(await api.GetAjustesByCedula(cedula));
        }, true);
    }

    const onEditEmployee = () => {
        navigateTo(<EditEmployee oldEmployee={employee} />)
    }

    const onEditException = () => {
        navigateTo(<EditException employee={employee} />)
    }

    const onEditIncapacitie = () => {
        navigateTo(<EditIncapacitie employee={employee} />)
    }

    const onEditDeduction = () => {
        navigateTo(<EditDeduction employee={employee} />)
    }
    const onEditAjuste = () => {
        navigateTo(<EditAjuste employee={employee} />)
    }

    const onDeleted = () => {
        showAlert(<Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}><WarningAmberIcon sx={{color: 'red'}}/>"Cuidado!"</Box>, <Typography>Esta seguro que quiere borrar este el puesto de trabajo "<b style={{color: "blue"}}>{employee.Nombre}</b>"? una ves borrado no se puede recuperar.</Typography>, async ()  => 
        { 
            await api.DeleteEmployee(employee.Cedula);
            setEmployee(await api.GetEmployee(cedula));
        }, true);
    }

    const onReactivate = async () => {
        setEmployee(employee => ({ 
            ...employee, 
            "Activo": true}));
        await  api.UpdateEmployee(employee.Cedula, { Activo: true } );
        setEmployee(await api.GetEmployee(cedula));
    }

    return <Box>
        {employee == null ?
            <Stack direction="column" spacing={2}>
                <Stack direction="row" spacing={2} alignItems="center">
                    <Skeleton variant="circular" width={70} height={70} />
                    <Box>
                        <Skeleton variant="rectangular" width={210} height={60} />
                        <Skeleton variant="rectangular" width={150} height={30} />
                    </Box>
                </Stack>
                 <Skeleton variant="rectangular" width={210} height={500} />
            </Stack>
            :
            <Stack direction="column" spacing={2}>
                <Stack direction="row" spacing={2} alignItems="center">
                    <Avatar sx={{ bgcolor: (employee.Activo ? deepPurple[500] : grey[400]), width: 75, height: 75, fontSize: 30 }}>
                        {employee.Nombre.substring(0, 1)}
                    </Avatar>
                    <Box sx={{ textAlign: 'left' }}>
                        <Typography variant="h4" >
                            {employee.Nombre + " " + employee.Apellidos}
                        </Typography>
                        <Typography variant="h5" style={{ color: grey[500] }}>
                            {employee.Cargo}
                        </Typography>
                    </Box>
                </Stack>
                <Stack direction="row" spacing={2} alignItems="flex-start" justifyContent="flex-start">
                    <Paper elevation={3} sx={{ textAlign: 'left', padding: 1,width:300, height:618 }}>
                        <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ width: '100%' }}>
                        <Typography variant="h6" gutterBottom >
                            {"Datos Generales"}
                        </Typography>
                        <Button disabled={!employee.Activo} onClick={onEditEmployee}><CreateIcon/>Editar</Button>
                        </Stack>
                        <Stack direction="column" spacing={1} sx={{ padding: 2 }}>
                            <Typography variant="body1" style={{ color: 'black' }}>
                                {"Email:"}
                            </Typography>
                            <Typography variant="body2" style={{ color: grey[700] }}>
                                {employee.Email}
                            </Typography>
                            <Divider orientation='horizontal' flexItem></Divider>
                            <Typography variant="body1" style={{ color: 'black' }} >
                                {"Salario: "} <Typography variant="subscript1" style={{ color: 'gray' }} fontSize={9}>(Mensual)</Typography>
                            </Typography>
                            <Typography variant="body2" style={{ color: grey[700] }}>
                                {formatter.format(employee.Salario)}
                            </Typography>
                            <Divider orientation='horizontal' flexItem></Divider>
                            <Typography variant="body1" style={{ color: 'black' }}>
                                {"FechaDeInicio:"}
                            </Typography>
                            <Typography variant="body2" style={{ color: grey[700] }}>
                                {employee.FechaDeInicio}
                            </Typography>
                            <Divider orientation='horizontal' flexItem></Divider>
                            <Typography variant="body1" style={{ color: 'black' }}>
                                {"Cedula:"}
                            </Typography>
                            <Typography variant="body2" style={{ color: grey[700] }}>
                                {employee.Cedula}
                            </Typography>
                            <Divider orientation='horizontal' flexItem></Divider>
                            <Typography variant="body1" style={{ color: 'black' }}>
                                {"Jornada:"}
                            </Typography>
                            <Typography variant="body2" style={{ color: grey[700] }}>
                                {employee.Jornada}h
                            </Typography>
                            <Divider orientation='horizontal' flexItem></Divider>
                            <Typography variant="body1" style={{ color: 'black' }}>
                                {"FechaDeSalida:"}
                            </Typography>
                            <Typography variant="body2" style={{ color: grey[700] }}>
                                {employee.FechaDeSalida}
                            </Typography>
                            <Divider orientation='horizontal' flexItem></Divider>
                            <Typography variant="body1" style={{ color: 'black' }}>
                                {"Horario:"}
                            </Typography>
                            <Typography variant="body2" style={{ color: grey[700] }}>
                                {employee.Horario}
                            </Typography>
                            <Divider orientation='horizontal' flexItem></Divider>
                            <Typography variant="body1" style={{ color: 'black' }}>
                                {"Activo:"}
                            </Typography>
                            <Typography variant="body2" style={{ color: grey[700] }}>
                                {employee.Activo ? "Si" : "No"}
                            </Typography>
                            {employee.Activo? 
                            <Button variant='contained' sx={{backgroundColor:"red", margin:10}} spacing={1} onClick={onDeleted} >Borrar</Button> :
                            <Button variant='contained' sx={{backgroundColor:"darkgreen", margin:10}} spacing={1} onClick={onReactivate} >Activar</Button>
                            }
                        </Stack>
                    </Paper>
                    <Stack direction="column" spacing={2} alignItems="center" justifyContent="flex-start">
                        <Paper elevation={3} sx={{ textAlign: 'left', padding: 1,width:820, height: 400 }}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <Tabs value={selectedTab} onChange={handleTabChange} aria-label="employee events">
                                <Tab label="Extras y Ausencias" {...a11yProps(0)} />
                                <Tab label="Incapacidades" {...a11yProps(1)} />
                                <Tab label="Otras Deducciones" {...a11yProps(2)} />
                                <Tab label="Otros Ajustes" {...a11yProps(3)} />
                                <Tab label="Pagos" {...a11yProps(4)} />
                                </Tabs>
                            </Box>
                            <CPTabPanel value={selectedTab} index={0}>
                                <Stack direction="row" alignItems={'center'} justifyContent={'space-between'}>
                                <Typography variant="h6" gutterBottom alignContent={'center'}>
                                    {" "}
                                </Typography>
                                <Button disabled={!employee.Activo} onClick={onAddException}><AddCircleIcon/></Button>
                                </Stack>
                                <ExceptionGrid employee={employee} exceptions={exceptions} onSelected={onSelectedException} onDeleted={onDeleteException}  />
                            </CPTabPanel>
                            <CPTabPanel value={selectedTab} index={1}>
                                <Stack direction="row" alignItems={'center'} justifyContent={'space-between'}>
                                <Typography variant="h6" gutterBottom alignContent={'center'}>
                                    {" "}
                                </Typography>
                                <Button disabled={!employee.Activo} onClick={onAddIncapacities}><AddCircleIcon/></Button>
                                </Stack>
                                <IncapacitieGrid employee={employee} incapacities={incapacities} onSelected={onSelectedIncapacities} onDeleted={onDeleteIncapacitie} />
                            </CPTabPanel>
                            <CPTabPanel value={selectedTab} index={2}>
                                <Stack direction="row" alignItems={'center'} justifyContent={'space-between'}>
                                <Typography variant="h6" gutterBottom alignContent={'center'}>
                                    {" "}
                                </Typography>
                                <Button disabled={!employee.Activo} onClick={onAddDeduction}><AddCircleIcon/></Button>
                                </Stack>
                                <DeductionGrid employee={employee} deductions={deductions} onSelected={onSelectedDeduction} onDeleted={onDeleteDeduction}  />
                            </CPTabPanel>
                            <CPTabPanel value={selectedTab} index={3}>
                                <Stack direction="row" alignItems={'center'} justifyContent={'space-between'}>
                                <Typography variant="h6" gutterBottom alignContent={'center'}>
                                    {" "}
                                </Typography>
                                <Button disabled={!employee.Activo} onClick={onAddAjuste}><AddCircleIcon/></Button>
                                </Stack>
                                <AjusteGrid employee={employee} ajustes={ajustes} onSelected={onSelectedAjuste} onDeleted={onDeleteAjuste}   />
                            </CPTabPanel>
                            <CPTabPanel value={selectedTab} index={4}>
                                <Stack direction="row" alignItems={'center'} justifyContent={'space-between'}>
                                <Typography variant="h6" gutterBottom alignContent={'center'}>
                                    {" "}
                                </Typography>
                                </Stack>
                                <PaymentsGrid employee={employee} payments={payments} onSelected={onSelectePayment} onDeleted={onDeletePayment}  />
                            </CPTabPanel>
                        </Paper>
                        <Paper elevation={3} sx={{ textAlign: 'left', padding: 1,width:820, height:200 }}>
                            <Typography variant="h6" gutterBottom alignContent={'center'}>Acciones</Typography>
                                                        
                            <Stack direction="row" spacing={1} margin={2}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    label="Desde:"
                                    fullWidth
                                    required
                                    value={dayjs(periodoPago.fechaInicio)}
                                    onChange={(newValue) => {
                                        setPeridoPago({ ...periodoPago, fechaInicio: newValue.format("YYYY-MM-DD") });
                                    }}
                                    renderInput={(params) => (
                                        <CPTextField
                                            {...params}
                                            required
                                            fullWidth
                                            variant="outline"
                                        />
                                    )}
                                />
                                </LocalizationProvider>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    label="Hasta:"
                                    fullWidth
                                    required
                                    value={dayjs(periodoPago.fechaFinal)}
                                    onChange={(newValue) => {
                                        setPeridoPago({ ...periodoPago, fechaFinal: newValue.format("YYYY-MM-DD") });
                                    }}
                                    renderInput={(params) => (
                                        <CPTextField
                                            {...params}
                                            required
                                            fullWidth
                                            variant="outline"
                                        />
                                    )}
                                />
                                </LocalizationProvider>
                                <Button disabled={!employee.Activo} variant='contained' sx={{margin:2}} onClick={ () => navigateTo(<PayRollPage employee={employee} fromDate={periodoPago.fechaInicio} toDate={periodoPago.fechaFinal}/>) }>Calcular Pago</Button>
                            </Stack>
                        </Paper>

                    </Stack>
                </Stack>
            </Stack>
        }
    </Box>
}

export default Employee;
