import axios from 'axios';
import { AuthenticationDetails, CognitoUser, CognitoUserPool } from 'amazon-cognito-identity-js';

class ApiManager {
  constructor(user, session, onLogoutCallback, onErrorCallback) {
    this.cognitoUser = user;
    this.session = session;
    this.onLogoutCallback = onLogoutCallback;
    this.onError = onErrorCallback;
    this.accessToken = session.idToken.jwtToken;
    this.apiBaseUrl = 'https://api.controlplanilla.com'; // Reemplaza con la URL base de tu API Gateway
  }

  async checkTokenAndRefreshIfNeeded(calledAction) {
    const refreshToken = this.session.getRefreshToken();
    const accessToken = this.session.getAccessToken().getJwtToken();
    const idToken = this.session.getIdToken().getJwtToken();
    const now = Math.floor(new Date() / 1000);
    const isAccessTokenExpired = this.session.getAccessToken().getExpiration() < now;

    if (isAccessTokenExpired) {
        console.log('Access token has expired, refreshing...');
        this.cognitoUser.refreshSession(refreshToken, async (err, session) => {
            if (err) {
                console.error('Token refresh failed:', err);
                throw new Error('Token refresh failed!');;
            }
            else {
              this.session = session;
              this.accessToken = this.session.getAccessToken().getJwtToken();
              return await calledAction();
            }
            console.log('Token was successfully refreshed.');
            // Actualizar el estado del usuario o almacenar el nuevo token
        });
   
    } else {
       // console.log('Access token is still valid.');
       return await calledAction();
    }
  }

  async callApiEndpoint(endpoint, method = 'GET', data = null) {
    try {
      const response = await this.checkTokenAndRefreshIfNeeded(async () => { 
        return await axios({
          method,
          url: `${this.apiBaseUrl}/${endpoint}`,
          headers: {
            Authorization: `Bearer ${this.accessToken}`,
          },
          data,
        });
      } );

      return response.data;
    } catch (error) {
      if (this.onError !== null && (error.response?.status == 500 || error.response?.status == 400))
      {
        this.onError("Error: " + error.response.data.message, error.response.data.err);
      }
      else if (this.onError !== null && error.response?.status == 404)
      {
        this.onError("Error: Operacion Invalidad", "El elemento que intento modificar no fue encontrado en el servidor.");
      }
      else if (this.onError !== null && error.response?.status == 403)
      {
        this.onError("Error: Operacion no authorizada", "Usted no tiene permisos para realizar esta modificion.");
      }
      else {
        this.onLogoutCallback();
        this.onError("Sesión expirada expirada", "Su sesión fue cerrada por inactividad o devido a una operacion invalida.");
      }
      return null;
    }
  }

  async GetAllowCompaniesFor(userEmail) {
    try {
      const response = await axios({
        method: 'GET',
        url: `${this.apiBaseUrl}/m/utils/getAllowCompanies?em=${userEmail.toLowerCase().trim()}`,
        headers: {
          Authorization: `Bearer ${this.accessToken}`,
        },
      });

      return response.data;
    } catch (error) {
      this.onLogoutCallback();
      return null;
    }
  }

  async GetCurrentUserCompany() {
    const response = await this.callApiEndpoint("usersManagement");
    return response;
  }

  // Employee related functions
  async GetEmployees() {
    return await this.callApiEndpoint("m/EmployeeCrud");
  }
  
  async GetEmployee(cedula) {
    return await this.callApiEndpoint("m/EmployeeCrud?cedula=" + cedula);
  }
  
  async CreateEmployee(employee) {
    if (employee) {
      return await this.callApiEndpoint("m/EmployeeCrud", "POST", JSON.stringify(employee));
    }
  }
  async UpdateEmployee(cedula, employee) {
    if (employee) {
      return await this.callApiEndpoint("m/EmployeeCrud?cedula="+cedula, "PUT", JSON.stringify(employee));
    }
  }
 
  async DeleteEmployee(cedula) {
    if (cedula) {
      return await this.callApiEndpoint("m/EmployeeCrud?cedula=" + cedula, "DELETE");
    }
  }

  // Work Schedules related functions
  async GetWorkSchedules() {
    return await this.callApiEndpoint("m/WorkingSchedules");
  }

   // Work Schedules related functions
   async GetWorkSchedule(codigo) {
    if (codigo && codigo.trim() != "") {
      return await this.callApiEndpoint("m/WorkingSchedules?Codigo=" + codigo);
    }
  }
  
  async CreateWorkSchedule(workSchedule) {
    if (workSchedule) {
      return await this.callApiEndpoint("m/WorkingSchedules", "POST", JSON.stringify(workSchedule));
    }
  }
  
  async DeleteWorkSchedule(codigo) {
    if (codigo) {
      return await this.callApiEndpoint("m/WorkingSchedules?Codigo=" + codigo, "DELETE");
    }
  }
  
  // Exceptions related functions
  async GetExceptions() {
    return await this.callApiEndpoint("m/EmployeeExeptions");
  }
  async GetExceptionsByCedula(cedula) {
    if (cedula) {
      return await this.callApiEndpoint("m/EmployeeExeptions?cedula=" + cedula);
    }
  }
  
  async CreateException(exception) {
    if (exception) {
      return await this.callApiEndpoint("m/EmployeeExeptions", "POST", JSON.stringify(exception));
    }
  }
  
  async DeleteException(idException, cedula) {
    if (idException) {
      return await this.callApiEndpoint("m/EmployeeExeptions?idException=" + idException + "&cedula="+cedula, "DELETE");
    }
  }
  
  // Incapacities related functions
  async GetIncapacities() {
    return await this.callApiEndpoint("m/Incapacidades");
  }
  async GetIncapacitiesByCedula(cedula) {
    if (cedula) {
      return await this.callApiEndpoint("m/Incapacidades?cedula=" + cedula);
    }
  }
  
  
  async CreateIncapacitie(incapacitie) {
    if (incapacitie) {
      return await this.callApiEndpoint("m/Incapacidades", "POST", JSON.stringify(incapacitie));
    }
  }
  
  async DeleteIncapacitie(incapacityID,cedula) {
    if (incapacityID) {
      return await this.callApiEndpoint("m/Incapacidades?incapacityID=" + incapacityID + "&cedula="+cedula, "DELETE");
    }
  }
  
  // Deductions related functions
  async GetDeductions() {
    return await this.callApiEndpoint("m/DeduccionesAddicionales");
  }

  async GetDeductionsByCedula(cedula) {
    if (cedula) {
      return await this.callApiEndpoint("m/DeduccionesAddicionales?cedula=" + cedula);
    }
  }
  
  async CreateDeduction(deduction) {
    if (deduction) {
      return await this.callApiEndpoint("m/DeduccionesAddicionales", "POST", JSON.stringify(deduction));
    }
  }
  
  async DeleteDeduction(deductionID,cedula) {
    if (deductionID) {
      return await this.callApiEndpoint("m/DeduccionesAddicionales?deductionID=" + deductionID + "&cedula="+cedula, "DELETE");
    }
  }
  
  // Adjustments related functions
  async GetAjustes() {
    return await this.callApiEndpoint("m/Ajustes");
  }
  
  async GetAjusteById(id) {
    if (id) {
      return await this.callApiEndpoint("m/Ajustes?IDAjuste=" + id);
    }
  }
  
  async GetAjustesByCedula(cedula) {
    if (cedula) {
      return await this.callApiEndpoint("m/Ajustes?cedula=" + cedula);
    }
  }

  async CreateAjuste(ajuste) {
    if (ajuste) {
      return await this.callApiEndpoint("m/Ajustes", "POST", JSON.stringify(ajuste));
    }
  }
  
  async DeleteAjuste(id,cedula) {
    if (id) {
      return await this.callApiEndpoint("m/Ajustes?IDAjuste=" + id + "&cedula="+cedula, "DELETE");
    }
  }
  

  async CalculatePayroll(cedula, desde, hasta) {
    const response = await this.callApiEndpoint("m/utils/payRoll?ced="+cedula+"&s="+desde+"&e="+hasta);
    return response;
  }

  async GetCompany(companyID) {
    const response = await this.callApiEndpoint("m/Companies?cID="+companyID);
    return response;
   
  }

  async GetEmployeePayments(cedula) {
    const response = await this.callApiEndpoint("m/payments?ced="+cedula);
    return response;
  }
  async GetPayment(IDPayment) {
    const response = await this.callApiEndpoint("m/payments?id="+IDPayment);
    return response;
  }

  async CalculateCompanyPayroll(desde, hasta) {
    const response = await this.callApiEndpoint("m/utils/calcCompayPayroll?s="+desde+"&e="+hasta);
    return response;
  }

  async DoPayment(cedula, desde, hasta) {
    const response = await this.callApiEndpoint("m/utils/payRoll?ced="+cedula+"&s="+desde+"&e="+hasta+"&pay=true&sm=true");
    return response;
  }

  async ResendPayment(cedula, desde, hasta) {
    const response = await this.callApiEndpoint("m/utils/payRoll?ced="+cedula+"&s="+desde+"&e="+hasta+"&pay=false&sm=true");
    return response;
  }

  async DeletePago(id, cedula)
  {
    const response = await this.callApiEndpoint("m/payments?id="+id, "DELETE");
    return response;
  }

}

export default ApiManager;