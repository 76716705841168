import React from 'react';
import { useMemo } from 'react';
import PropTypes from 'prop-types'; // Import PropTypes
import { DataGrid, GridRowsProp, GridColDef, GridActionsCell, GridActionsCellItem} from '@mui/x-data-grid';
import { List, ListItemAvatar, Avatar, ListItemText, ListItemButton } from '@mui/material';
import { deepPurple, deepOrange } from '@mui/material/colors';
import Exceptions from 'pages/exceptionsPages/Exceptions';
import CreateIcon from '@mui/icons-material/Create';
import DeleteIcon from '@mui/icons-material/Delete';
import {useNavigation} from 'lib/NavigationContext'
import EditException from 'pages/exceptionsPages/EditException';


const ExceptionGrid = ({ employee, exceptions, onSelected, onDeleted }) => {
  // Basic validation to check if exceptions is an array
  const { navigateTo } = useNavigation();
  const deleteAction = (id) => {
    if (onDeleted)
        onDeleted(id);
  }
  const selectAction = (id) => {
    if (onSelected)
        onSelected(id);
  }
  const columns = React.useMemo(() => {
    return [
      { field: 'Cedula', headerName: 'Cedula', width: 150 },
      { field: 'Fecha', headerName: 'Fecha', width: 150 },
      { field: 'Horas', headerName: 'Horas', width: 100, 
      valueGetter: (params) => {
        if (!params.value) {
          return params.value;
        }
        // Convert the decimal value to a percentage
        return params.value +" h";
      },
      },
      { field: 'Razon', headerName: 'Razon', width: 200 },
      {
        field: 'actions',
        width: 30,
        type: 'actions',
        getActions: ({ id, row }) => {
          return [
            <GridActionsCellItem
              icon={<CreateIcon/>}
              label="Edit"
              onClick={() => {
                navigateTo(<EditException employee={employee} oldException={row} />)
              }}
            />,
          ];
        },
      },
      {
        field: 'action',
        width: 30,
        type: 'actions',
        getActions: ({ id, row }) => {
          return [
            <GridActionsCellItem
              icon={<DeleteIcon/>}
              label="DELETE"
              onClick={() => {
                deleteAction (id)
              }}
            />,
          ];
        },
    },
    ];
  },[]);


  if (!Array.isArray(exceptions)) {
    return <div>Error: exceptions is not an array</div>;
  }
    return (
    <DataGrid rows={exceptions} columns={columns} 
    getRowId={(row)=>{ return row?row.IDException:0;}} sx={{height:250}}
    initialState={{
      sorting: {
        sortModel: [{ field: 'Fecha', sort: 'desc' }],
      },
    }}
    />
  );
};

// PropTypes for type checking
ExceptionGrid.propTypes = {
  exceptions: PropTypes.arrayOf(PropTypes.shape({
    IDException: PropTypes.string.isRequired,
    Cedula: PropTypes.string.isRequired,
    Fecha: PropTypes.string.isRequired,
    Horas: PropTypes.number.isRequired,
    Razon: PropTypes.string.isRequired
  })).isRequired,
  onSelected: PropTypes.func.isRequired,
  onDeleted: PropTypes.func.isRequired
};

export default ExceptionGrid;
