import React from 'react';
import PropTypes from 'prop-types'; // Import PropTypes
import { DataGrid, GridRowsProp, GridColDef, GridActionsCellItem } from '@mui/x-data-grid';
import MoreIcon from '@mui/icons-material/More';
import { blue } from '@mui/material/colors';
import PayRollPage from 'pages/Reports/PayRollPage';
import { Container } from '@mui/system';
import { Box, Dialog, Paper, DialogTitle, DialogContent } from '@mui/material';
import { useNavigation } from 'lib/NavigationContext';

const style = {
  position: 'absolute',
  top: '20%',
  left: '20%',
  transform: 'translate(-20%, -20%)',
  height: 600,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const CompanyPayrollGrid = ({ payrolls, onSelected }) => {
    
    const formatter = new Intl.NumberFormat('es-CR', { style: 'currency', currency: 'CRC' });
    const [rowSelectionModel, setRowSelectionModel] = React.useState([]);
    const { navigateTo } = useNavigation();
    const openDetail = (row) =>
    {
      //console.log(row);
      navigateTo(<PayRollPage Payment={row}/>, true);
    }
 

    const columns = () => {
        const cols = [
    { field: 'Cedula', headerName: 'Cedula', width: 150 },
    { field: 'Nombre', headerName: 'Nombre', width: 200 },
    { field: 'Cargo', headerName: 'Cargo', width: 150 },
    { field: 'FechaInicio', headerName: 'Inicio', width: 150, 
    valueGetter: (params) => {
        if (params.value) {
        return new Date(params.value).toLocaleDateString("es-CR");
        }
        
        return "-";
    } },
    { field: 'FechaFinal', headerName: 'Fin', width: 150, 
    valueGetter: (params) => {
        if (params.value) {
        return new Date(params.value).toLocaleDateString("es-CR");
        }
        
        return "-";
    },
    },
    { field: 'Bruto', headerName: 'Bruto', width: 150, 
    valueGetter: (params) => {
        if (params.value != undefined) {
        return formatter.format(params.value);
        }
    
        return formatter.format(0);
    } },
    { field: 'Neto', headerName: 'Neto', width: 150, 
    valueGetter: (params) => {
        if (params.value != undefined) {
        return formatter.format(params.value);
        }
    
        return formatter.format(0);
    } },
    { field: 'CCSSEmpleado', headerName: 'CCSS Empleado', width: 150, 
    valueGetter: (params) => {
        if (params.value != undefined) {
        return formatter.format(params.value);
        }
    
        return formatter.format(0);
    } },
    { field: 'CCSSPatrono', headerName: 'CCSS Patrono', width: 150, 
    valueGetter: (params) => {
        if (params.value != undefined) {
        return formatter.format(params.value);
        }
    
        return formatter.format(0);
    } },
    { field: 'Renta', headerName: 'Renta', width: 100, 
    valueGetter: (params) => {
        if (params.value != undefined) {
        return formatter.format(params.value);
        }
    
        return formatter.format(0);
    } },
    {
      field: 'EstadoDelPago',
      width: 80,
      headerName: 'Pagado?',
      valueGetter: (params) => {
          if (params.value == 0) {
            return 'NO';
          }
          else {
            return 'SI';
          }
      }
    },
    {
      field: 'action',
      width: 30,
      type: 'actions',
      getActions: ({ id, row }) => {
        return [
          <GridActionsCellItem
            icon={<MoreIcon sx={{color: blue[500]}}/>}
            label="Detalle"
            onClick={() => {
              openDetail(row);
            }}
          />,
        ];
      },
  },
    ];

    return cols;

    }

    // Basic validation to check if payrolls is an array
  if (!Array.isArray(payrolls)) {
    return <div>No hay datos</div>;
  }

  return (
    <DataGrid checkboxSelection
    onRowSelectionModelChange={(newRowSelectionModel) => {
      setRowSelectionModel(newRowSelectionModel);
      onSelected(newRowSelectionModel);
    }}
    isRowSelectable={(params) => params.row.EstadoDelPago == 0}
    rowSelectionModel={rowSelectionModel}
    rows={payrolls} columns={columns()} 
    getRowId={(row)=>{ return row?row.IDPayment :0;}} 
    initialState={{
      sorting: {
        sortModel: [{ field: 'FechaFinal', sort: 'desc' }],
      },
    }}
    />
  );
};

// PropTypes for type checking
CompanyPayrollGrid.propTypes = {
  payrolls: PropTypes.arrayOf(PropTypes.shape({
    IDPayment : PropTypes.string.isRequired,
    companyID :  PropTypes.number.isRequired,
    Cedula: PropTypes.string.isRequired,
    Nombre: PropTypes.string.isRequired,
    Cargo: PropTypes.string.isRequired,
    FechaInicio: PropTypes.string.isRequired,
    FechaFinal: PropTypes.string.isRequired,
    Bruto: PropTypes.number.isRequired,
    Neto: PropTypes.number.isRequired,
    CCSSEmpleado: PropTypes.number.isRequired,
    CCSSPatrono: PropTypes.number.isRequired,
    Renta: PropTypes.number.isRequired,
    EstadoDelPago: PropTypes.number.isRequired
  })).isRequired,
  onSelected: PropTypes.func.isRequired
};

export default CompanyPayrollGrid;
