import React, { useState, useEffect } from 'react';
import { useApiManager } from 'lib/ApiContext';
import { Grid, Select, MenuItem, Breadcrumbs, Typography, Avatar, Divider, Button, TextField, Chip, emphasize, Backdrop, CircularProgress } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import Paper from '@mui/material/Paper';
import { styled, useTheme } from '@mui/material/styles';
import DashboardIcon from '@mui/icons-material/Dashboard';
import Employee from './Employee';
import { CPTextField, CPSelect, StyledBreadcrumb } from 'components/CPStyles';
import Employees from './Employees';
 
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import {useNavigation} from '../../lib/NavigationContext'

const initialModel = {
    Horario: '',
    Nombre: '',
    Apellidos: '',
    Cargo: '',
    Activo: true,
    FechaDeSalida: '',
    Jornada: 8,
    Email: '',
    Cedula: '',
    FechaDeInicio: new Date().toJSON(),
    Salario: 0,
};

const NewEmployee = () => {

    const api = useApiManager();
    const theme = useTheme();
    const { navigateTo } = useNavigation();
    const [openBackdrop, setOpenBackdrop] = React.useState(false);

    const [employee, setEmployee] = useState(initialModel);
    const [workingSchedules, setWorkingSchedules] = useState([]);

    useEffect(()=> {
        api.GetWorkSchedules().then( 
            r => 
            setWorkingSchedules(r)
            );
    },[]);


    const [errors, setErrors] = useState({});

    const validateFields = () => {
        let newErrors = {};
        for (const key in employee) {
            if (key != 'FechaDeSalida' && key != 'Jornada' && key != 'Horario') {
                if (typeof employee[key] === 'string' && employee[key].trim() === '') {
                    newErrors[key] = 'Este campo es requerido';
                }
            }
        }
        // check for Salario > 0
        if (employee.Salario <= 0)
        {
            newErrors['Salario'] = 'Salario debe ser mayor que 0';
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name == "Cargo")
        {
            const newSchedule = workingSchedules.find((job) => job.Codigo === value);
            console.log("Setting Cargo and Horario:", value, newSchedule.DiasLaborales); // More debugging
            setEmployee(prevEmployee => ({ 
                ...prevEmployee, 
                "Cargo": newSchedule.Codigo, 
                "Horario": newSchedule.DiasLaborales 
            }));
        }
        else {
            setEmployee({ ...employee, [name]: value });
        }
    };

    const handleBlur = (e) => {
        const { name, value } = e.target;
        if (name != 'FechaDeSalida' && name != 'Jornada' && name != 'Horario') {
            if (value.trim() === '') {
                setErrors({ ...errors, [name]: 'Este campo es requerido' });
            } else {
                setErrors({ ...errors, [name]: undefined });
            }
        }
    };

    const handleSubmit = async () => {
        // Call your API to add the new employee
        // Example: await api.addEmployee(employee);
        setOpenBackdrop(true);

        if (!validateFields()) {
            setOpenBackdrop(false)
        }
        else {
            api.CreateEmployee(employee).catch((e) => {
                setOpenBackdrop(false); console.error(e);
            })
                .then(navigateTo(<Employee cedula={employee.Cedula} />));
        }
    };

    const handleNavigateBack = (target) =>
    {
        navigateTo(<Employees />);
    }

    return <form>
        <Breadcrumbs aria-label="breadcrumb">
            <StyledBreadcrumb
                component="a"
                href="#"
                onClick={()=> handleNavigateBack("dashboard")}
                label="Principal"
                icon={<DashboardIcon fontSize="small" />}
            />
            <StyledBreadcrumb component="a" href="#" label="Empleados" onClick={()=> handleNavigateBack("empleados")} />
            <StyledBreadcrumb
                label="Nuevo Empleado" 
            />
        </Breadcrumbs>
        <Paper elevation={3} sx={{ textAlign: 'left', padding: 1, margin: 2 }}>
            <Typography variant="h4" color="primary" gutterBottom>
                {"Nuevo Empleado"}
            </Typography>
            <br />
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={4}>
                    <CPTextField
                        required
                        id="Cedula"
                        name="Cedula"
                        label="Cedula"
                        value={employee.Cedula}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={!!errors["Cedula"]}
                        helperText={errors["Cedula"]}
                        defaultValue=""
                        variant="filled"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={4}>
                    <CPTextField
                        required
                        id="Nombre"
                        name="Nombre"
                        label="Nombre"
                        value={employee.Nombre}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={!!errors["Nombre"]}
                        helperText={errors["Nombre"]}
                        defaultValue=""
                        variant="filled"
                        color='secondary'
                        fullWidth
                    />
                </Grid>
                <Grid item xs={4}>
                    <CPTextField
                        required
                        id="Apellidos"
                        name="Apellidos"
                        label="Apellidos"
                        value={employee.Apellidos}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={!!errors["Apellidos"]}
                        helperText={errors["Apellidos"]}
                        defaultValue=""
                        variant="filled"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={8}>
                    <CPTextField
                        required
                        id="Email"
                        name="Email"
                        label="Correo Electronico"
                        value={employee.Email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={!!errors["Email"]}
                        helperText={errors["Email"]}
                        defaultValue=""
                        variant="filled"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={4}>
                    
                    <CPSelect id="Cargo"
                        name="Cargo"
                        label="Cargo"
                        displayEmpty
                        value={employee.Cargo}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={!!errors["Cargo"]}
                        helperText={errors["Cargo"]}
                        variant="filled"
                        fullWidth>
                             <MenuItem disabled value="">
                                <em>Puesto*</em>
                            </MenuItem>
                            {
                                workingSchedules.map((job) => {
                                    return <MenuItem key={job.Codigo} value={job.Codigo}>{job.Codigo}</MenuItem>
                                })
                              
                            }
                    </CPSelect>
                </Grid>
                <Grid item xs={4}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                        label="Inicio de labores"
                        fullWidth
                        required
                        value={dayjs(employee.FechaDeInicio)}
                        onChange={(newValue) => {
                            setEmployee({ ...employee, FechaDeInicio: newValue.format("YYYY-MM-DD") });
                        }}
                        renderInput={(params) => (
                            <CPTextField 
                            {...params}
                            required
                            fullWidth
                            error={!!errors["FechaDeInicio"]}
                            helperText={errors["FechaDeInicio"]}
                            variant="filled"
                            />
                        )}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={4}></Grid>
                <Grid item xs={4}>
                    <CPTextField
                        required
                        id="Salario"
                        name="Salario"
                        label="Salario (colones)"
                        value={employee.Salario}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={!!errors['Salario']}
                        helperText={errors['Salario']}
                        type='Number'
                        defaultValue='0'
                        variant="filled"
                        fullWidth
                    />
                </Grid>
            </Grid>
            <br />
            <Divider orientation='horizontal' />
            <Button onClick={handleSubmit} variant="contained" sx={{ margin: 2 }}>Crear</Button>
        </Paper>
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={openBackdrop}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
    </form>
}

export default NewEmployee;